@import "style.global.scss";

.stopScroll {
    height: 100vh;
    overflow: hidden;
}

.Page {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .content {
        background: $bg;
        pointer-events: all;

        section {
            width: 100%;
            max-width: $maxWidth;
            margin: 0 auto;
            padding: 5em 1em;

            .sectionTitle {
                // color: $accent;
                font-weight: bold;
                font-size: 2em;
                display: flex;
                justify-content: center;
                // background: $secondary;
                // margin-bottom: 1.5em;

                h1 {
                    display: flex;
                    align-items: center;
                    color: $secondary;

                    span {
                        display: block;
                        width: 0.5em;
                        height: 50%;
                        margin-left: 0.1em;
                        background: $accent;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 100%;
                            width: 0;
                            height: 0;
                            $size: 0.38em;
                            border-top: $size solid transparent;
                            border-bottom: $size solid transparent;
                            border-left: $size solid $accent;
                        }
                    }
                }
            }

            .sectionSubTitle {
                text-align: center;
                margin-bottom: 5em;
                margin-top: -0.8em;
                font-weight: bold;
                color: $secondary3;
            }
        }

        #contact {
            max-width: 100%;
            margin: 0;
            padding: 0;
            background: linear-gradient(135deg, $accent, #02063d);
            scroll-margin: 4em;
            scroll-snap-align: start;
            scroll-snap-stop: normal;

            .sectionSpanBG {
                padding-top: 3em;

                .sectionTitle {
                    mix-blend-mode: difference;

                    h1 {
                        color: $primary;

                        span {
                            display: none;
                        }
                    }
                }

                .sectionSubTitle {
                    color: $secondary2;
                    margin-bottom: 3em;
                }
            }
        }
    }
}
