@import "style.global.scss";

.Projects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 2em;

    .projectCard {
        border-radius: $borderRadius;
        background: $primary;
        box-shadow: 0 5px 12px 0px $lightShadow;
        padding: 1.1em 1.5em;
        width: 100%;
        max-width: 21em;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .projectInfo {
            .projectHeader {
                margin-bottom: 1em;

                .projectTitle {
                    font-size: 1.8em;
                    line-height: 1em;
                }

                .projectOrg {
                    margin-top: 0.1em;
                    color: $secondary3;
                    font-size: 0.9em;
                }
            }

            .projectCover {
                width: 100%;
                border-radius: $borderRadius;
                box-shadow: 0 0 12px -0px $colorShadow;
            }

            .projectData {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.9em;
                color: $secondary3;
                margin-bottom: 1em;

                .projectTech {
                    display: flex;
                    gap: 0.3em;
                    align-items: center;
                }
            }

            .projectDescription {}
        }

        .projectLinks {
            .projectLinks {
                display: flex;
                gap: 0.5em;

                a {
                    flex: 1;
                    text-decoration: none;
                    margin-top: 1em;
                }

                .projectButton {
                    padding: 0.9em 0;
                    width: 100%;
                    border: none;
                    background: none;
                    border: 2px solid $secondary;
                    color: $secondary;
                    font-weight: bold;
                    font-size: 1em;
                    border-radius: $borderRadius;
                    height: max-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .projectButtonIcon {
                        height: 1.2em;
                        margin-right: 0.5em;
                    }

                    p {
                        margin-top: 0.2em;
                    }
                }

                .projectGithub {
                    border: 2px solid #202020;
                    color: #202020;

                    &:hover {
                        background: #202020;
                        color: $primary;
                    }
                }

                .projectWebsite {
                    border: 2px solid $accent;
                    color: $accent;

                    &:hover {
                        background: $accent;
                        color: $primary;
                    }
                }
            }

            .privateLink {
                width: 100%;
                background: #303030;
                color: white;
                text-align: center;
                margin-top: 1em;
                border-radius: $borderRadius;
                padding: 0.9em 0;
                font-size: 1em;
                font-weight: bold;
                display: flex;
                justify-content: center;

                .privateButtonIcon {
                    height: 1.2em;
                    margin-right: 0.5em;
                    margin-top: 0.1em;
                }
            }
        }
    }

    .loadWrapper {
        width: 100%;
        text-align: center;

        .loadMore {
            padding: 1em 1.2em;
            border: none;
            outline: none;
            background: $accent;
            color: $primary;
            font-weight: bold;
            border-radius: $borderRadius;
            box-shadow: 0px 0px 12px -5px $shadow;
            cursor: default;
        }
    }
}