$primary: white;
$secondary: #101010;
$secondary2: #c0c0c0;
$secondary3: #505050;
$accent: #0327d8;
$accent2: #039cd8;
// $bg: #f2f5f8;
$bg: #eef5f8;

$colorShadow: rgba(3, 39, 216, 0.1);
$lightShadow: rgba(0, 0, 0, 0.15);
$shadow: rgba(0, 0, 0, 0.3);
$darkShadow: rgba(0, 0, 0, 0.8);
$whiteShadow: rgba(255, 255, 255, 0.3);

$maxWidth: 70em;

$borderRadius: 0.5em;

$scrollbar-color: black;
