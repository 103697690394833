@import "style.global.scss";

html {
    scroll-behavior: smooth;
}

.App {
    .wip {
        z-index: 1000;
        background: linear-gradient(-45deg, #ffd900, #ffbb00);
        position: fixed;
        bottom: 2em;
        right: 2em;
        font-weight: bold;
        padding: 0.6em;
        text-align: center;
        border: 2px solid black;
        border-radius: 0.5em;
        box-shadow: 0 0 12px -5px rgba(0, 0, 0, 0.3);
    }
}

#cursor {
    position: fixed;
    background: $primary;
    z-index: 100;
    pointer-events: none;
    border-radius: 50%;
    mix-blend-mode: difference;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.3s;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.5em;
    margin-top: 30px;
    background: $bg;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 12px -5px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0px;
    background: $accent;
    // -webkit-box-shadow: inset 0 0 12px -5px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: $accent;
}

@media only screen and (max-width: 1130px) {
    ::-webkit-scrollbar {
        width: 0em;
    }
}
