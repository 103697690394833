@import "style.global.scss";

.Header {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: opacity 0.5s;
    z-index: -1;

    .title {
        max-width: 45em;
        width: 90%;
        text-align: center;
        pointer-events: all;
        position: fixed;
        z-index: -1;

        .intro {
            color: $secondary;
            position: relative;
            width: max-content;
            background: $primary;
            padding: 0.5em 1em;
            transform: rotate(-3deg);
            font-family: "Indie Flower";
            font-weight: bold;
            font-size: 1.3em;
        }

        .name {
            font-size: 5em;
            font-family: "Press Start 2P";
            // letter-spacing: 0.2em;
            margin-top: -0.3em;
            padding: 0.5em;
            position: relative;
            transform: rotate(-3deg);
            background: $secondary;
            color: $primary;
            text-align: center;
            box-shadow: 0 0 32px -5px $darkShadow;
        }

        .info {
            color: $primary;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            margin: 1em 0;
            font-weight: medium;
            font-size: 2rem;
            text-shadow: 2px 2px 0px $secondary;
            font-family: "Indie Flower";
            letter-spacing: 0.03em;
        }

        .callToAction {
            display: flex;
            justify-content: center;
            margin-top: 1.5em;
            gap: 1em;

            a {
                flex: 1;
                text-decoration: none;
            }

            button {
                width: 100%;
                padding: 1em 0;
                background: transparent;
                font-weight: bold;
                color: $primary;
                border-radius: $borderRadius;
                // cursor: pointer;
                border: 2px solid $primary;
                pointer-events: all;
                font-size: 1.2em;
                // font-family: "Press Start 2P";
                box-shadow: 0 0 32px -10px $darkShadow;

                display: flex;
                justify-content: center;

                img {
                    height: 1em;
                    margin-left: -0.5em;
                    opacity: 0;
                    transition: all 0.5s;
                }

                &:hover {
                    // background: linear-gradient(-45deg, $accent 0%, $accent2 100%);
                    background: $accent;
                    // border: 2px solid $accent;

                    .mainArrow {
                        margin-left: 2em;
                        opacity: 1;
                    }

                    img {
                        opacity: 1;
                        margin-left: 0;
                    }
                }
            }

            .primaryButton {
                background: $secondary;
            }
        }
    }

    .scrollIcon {
        position: fixed;
        border: 2px solid white;
        bottom: 1em;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 1.3em;
        height: 2.8em;
        background: black;
        border-radius: 1em;
        z-index: -1;
        opacity: 0.6;

        .scrollDot {
            width: 0.4em;
            height: 0.4em;
            background: white;
            border-radius: 50%;
            margin: 0 auto;
            margin-top: 0.3em;
            animation: bob 0.8s ease-in infinite alternate;
        }

        @keyframes bob {
            to {
                margin-top: 1.8em;
                opacity: 0.3;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .Header {
        .title {
            .name {
                font-size: 10vw;
            }

            .callToAction {
                font-size: 0.9em;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .Header {
        .title {
            .callToAction {
                img {
                    display: none;
                }
            }
        }
    }
}
