@import "style.global.scss";

.Footer {
    width: 100%;
    height: 55em;
    position: relative;
    background: $bg;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .gift {
        width: 95%;
        max-width: 40em;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        padding-top: 4em;

        .giftTitle {
            font-weight: bold;
            font-size: 1.6em;
            display: flex;
            justify-content: center;
            margin-bottom: 1em;

            h1 {
                display: flex;
                align-items: center;
                color: $secondary;

                span {
                    display: block;
                    width: 0.5em;
                    height: 50%;
                    margin-left: 0.1em;
                    background: $accent;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 100%;
                        width: 0;
                        height: 0;
                        $size: 0.38em;
                        border-top: $size solid transparent;
                        border-bottom: $size solid transparent;
                        border-left: $size solid $accent;
                    }
                }
            }
        }

        .giftHolder {
            width: 100%;
            padding: 0.1em;
            background: $primary;
            box-shadow: 0px 0px 12px 0px $lightShadow;
            border-radius: $borderRadius;
            position: relative;
            overflow: hidden;

            &::before {
                content: "";
                position: absolute;
                top: -2em;
                left: 0.5em;
                width: 2em;
                height: 10em;
                background: linear-gradient(#5672ff 0%, $accent 100%);
                z-index: 2;
                transform: rotate(45deg);
            }

            .giftImage {
                // height: 100%;
                width: 100%;
                height: 22.35em;
                border-radius: $borderRadius;
            }

            .giftOverlay {
                position: absolute;
                width: 50%;
                top: 0.1em;
                left: 0.1em;
                right: 0.1em;
                bottom: 0.5em;
                border-radius: $borderRadius;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                // background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 50%, transparent 50%, transparent 100%);
                // background: rgba(0, 0, 0, 1);
                background-image: linear-gradient(to bottom right, #303030 0%, #0327d8 100%);
                // opacity: 0.9;
                box-shadow: 5px 0px 12px -3px $darkShadow;

                .giftContent {
                    width: 100%;
                    height: 100%;
                    padding: 1em 1.5em;
                    padding-top: 3.2em;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    h1 {
                        font-size: 2.7em;
                        color: $primary;
                        line-height: 1em;
                        margin-bottom: 0.2em;
                    }

                    p {
                        color: $secondary2;
                        font-size: 1.1em;
                        // margin-bottom: 2em;
                    }

                    .giftButtons {
                        pointer-events: all;

                        .giftDownload {
                            width: 100%;
                            padding: 1em;
                            background: none;
                            border: 2px solid $primary;
                            color: $primary;
                            border-radius: $borderRadius;
                            font-weight: bold;
                            font-size: 1.2em;

                            &:hover {
                                background: $accent;
                            }
                        }

                        .giftView {
                            text-align: center;
                            color: $primary;
                            margin: 0 auto;
                            margin-top: 1em;
                            width: max-content;
                            padding: 0.2em 0;
                            border-bottom: 1px solid $primary;
                            font-size: 0.9em;
                            position: relative;

                            &::after {
                                content: "";
                                position: absolute;
                                bottom: 0em;
                                left: 0;
                                right: 0;
                                width: 0%;
                                height: 0.2em;
                                background: $primary;
                                margin: 0 auto;
                                transition: width 0.3s;
                            }

                            &:hover {
                                &::after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .foot {
        width: 100%;
        height: 50%;
        background: $secondary;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10em;
        padding-bottom: 7em;
        position: relative;

        .footWrapper {
            display: flex;
            width: 90%;
            max-width: $maxWidth;
            height: 100%;
        }

        .col {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .col1 {
            align-items: flex-start;
            justify-content: flex-start;

            p {
                color: $primary;
                margin-bottom: 1em;
            }

            img {
                width: 18em;
                margin-left: -2.5em;
            }
        }

        .col2 {
            a {
                color: $primary;
                text-decoration: none;
                padding: 0.2em 0;
                border-bottom: 0.1em solid $primary;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0em;
                    left: 0;
                    right: 0;
                    width: 0%;
                    height: 0.2em;
                    background: $primary;
                    margin: 0 auto;
                    transition: width 0.3s;
                }

                &:hover {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        .col3 {
            align-items: flex-end;
            justify-content: space-between;

            img,
            .footerSocials {
                width: 2em;
                margin-left: 0.5em;

                &:first-child {
                    margin-left: 0;
                    margin-right: 0.5em;
                }
            }

            p {
                color: $primary;
                font-weight: bold;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .foot {
        // font-size: 0.6em;
        padding-bottom: 1em !important;

        .footWrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2em;

            .col {
                text-align: center;
                // background-color: red;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .col1 {
                img {
                    margin: 0;
                }
            }

            .col2 {
                display: none;
            }

            .col3 {
                img {
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 675px) {
    .Footer {
        .gift {
            .giftHolder {
                .giftOverlay {
                    width: 100%;
                    opacity: 1;
                    background-image: linear-gradient(to bottom right, #303030 0%, #0327d8 100%);

                    .giftContent {
                        justify-content: center;
                        align-items: center;

                        h1 {
                            text-align: center;
                        }
                        p {
                            text-align: center;
                            margin-bottom: 1.5em;
                        }
                    }
                }
            }
        }
    }
}
