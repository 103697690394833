// Poppins
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
// Zen Tokyo Zoo
@import url("https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap");
// Otomanopee One
@import url("https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap");
// Ruslan Display
@import url("https://fonts.googleapis.com/css2?family=Ruslan+Display&display=swap");
// Press Start 2P
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
// Tourney
@import url("https://fonts.googleapis.com/css2?family=Tourney:wght@300;400;500;700;900&display=swap");
// Indie Flower
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
// Amatic SC
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");
// Architects Daughter
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap");
// Caveat Brush
@import url("https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap");
// Shadows Into Light
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");

@import "style.global.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    cursor: none;
}

html,
body {
    font-family: "Poppins", sans-serif;
}
