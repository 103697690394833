@import "style.global.scss";

.Contact {
    .cols {
        display: flex;
        width: 100%;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0 1em;
        padding-bottom: 2em;

        .col {
            flex: 1;
        }

        .col1 {
            .text {
                color: $primary;
                margin-bottom: 2em;
                font-size: 1.2em;
            }

            .contactButtons {
                display: flex;
                align-items: center;
                gap: 1em;

                .downloadResumeLink {
                    flex: 1;
                    text-decoration: none;

                    button {
                        width: 100%;
                        height: 100%;
                        outline: none;
                        border: none;
                        background: $primary;
                        padding: 1.3em 2em;
                        border-radius: $borderRadius;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .downloadIcon {
                            margin-top: 0.5em;
                            margin-right: 0.5em;
                        }

                        p {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            font-size: 2em;
                            font-weight: bold;
                            line-height: 0.75em;

                            span {
                                font-size: 0.5em;
                                font-weight: 500;
                            }
                        }
                    }
                }

                .socialLink {
                    width: 4.9em;
                    height: 4.9em;
                    padding: 1.2em;
                    border-radius: $borderRadius;
                    background: black;

                    img {
                        width: 100%;
                    }

                    &:hover {
                        background: $accent;
                    }
                }
            }

            .copyEmailButton {
                height: max-content;
                width: 100%;
                padding: 0.71em 0;
                border-radius: 0.3em;
                font-size: 1.8em;
                font-weight: bold;
                color: $primary;
                mix-blend-mode: difference;
                background: transparent;
                border: 2px solid $primary;
                margin-top: 0.5em;

                &:hover {
                    background: $primary !important;
                    color: $secondary !important;
                }
            }
        }

        .col2 {
            position: relative;

            .imageHolder {
                z-index: 50;
                position: relative;
                width: 100%;
                height: 100%;

                img {
                    position: absolute;
                    bottom: -4.7em;
                    right: 0;
                    height: 31em;
                }
            }
        }
    }
}

@media only screen and (max-width: 1020px) {
    .Contact {
        .cols {
            padding-bottom: 5em;
        }

        .col1 {
            width: 100%;
            max-width: 30em;
            margin: 0 auto;
        }

        .col2 {
            display: none;
        }
    }
}

@media only screen and (max-width: 430px) {
    .Contact {
        .cols {
            .col1 {
                .contactButtons {
                    gap: 0.5em;

                    .downloadResumeLink {
                        button {
                            padding: 1em;

                            p {
                                font-size: 6vw;
                            }
                        }
                    }

                    .socialLink {
                        padding: 0.5em;
                        width: 4.2em;
                        height: 4.2em;
                    }
                }

                .copyEmailButton {
                    font-size: 6.5vw;
                }
            }
        }
    }
}