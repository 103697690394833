@import "style.global.scss";

.Skills {
    .cards {
        display: flex;
        justify-content: center;
        margin-top: 2em;

        .card {
            flex: 1;
            height: 20em;
            background: $primary;
            box-shadow: 0px 5px 12px -5px $lightShadow;
            margin: 0 1em;
            border-radius: $borderRadius;
        }
    }
}
