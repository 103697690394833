@import "style.global.scss";

.Navbar {
    width: 100%;
    height: 4em;
    position: fixed;
    pointer-events: all;
    z-index: 100;

    .navbarBG {
        width: 100%;
        height: 4em;
        position: fixed;
        pointer-events: none;
        background: $bg;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.5s;
        box-shadow: 0 0 12px -5px $darkShadow;
    }

    .bar {
        max-width: $maxWidth;
        width: 100%;
        height: 100%;
        padding: 0 1em;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            letter-spacing: 0.1em;
            color: $primary;
            text-decoration: none;
        }
        .linkState {
            color: $secondary;
        }

        .left {
            display: flex;
            align-items: center;

            .menuIcon {
                height: 1.3em;
                width: 0;
                margin-bottom: 0.2em;
                margin-right: 0em;
                display: flex;
                align-items: center;
                position: relative;
                transition: 0.5s all;
                // overflow: hidden;

                .hamburger {
                    width: 100%;
                    height: 0.2em;
                    background: $primary;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0em;
                        width: inherit;
                        height: inherit;
                        background: inherit;
                        transform-origin: left top;
                        transition: 0.3s all;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0em;
                        width: inherit;
                        height: inherit;
                        background: inherit;
                        transform-origin: left bottom;
                        transition: 0.3s all;
                    }
                }

                .hamburgerColor {
                    background: $secondary;
                }

                .menuOpen {
                    background: transparent;

                    &::before {
                        transform: rotateZ(41deg);
                        background: $secondary;
                        margin-left: 0.2em;
                    }
                    &::after {
                        transform: rotateZ(-41deg);
                        background: $secondary;
                        margin-left: 0.2em;
                    }
                }
            }

            .title {
                font-family: "Amatic SC";
                font-size: 2.1em;
                font-weight: bold;
            }
        }

        .navLinks {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 3em;
            font-size: 1em;

            .link {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -0.3em;
                    left: 0;
                    right: 0;
                    width: 0%;
                    height: 0.2em;
                    background: $accent;
                    margin: 0 auto;
                    transition: width 0.3s;
                }

                &:hover {
                    &::after {
                        width: 100%;
                    }
                }
            }

            .resume {
                // background: $accent;
                padding: 0.4em 1em;
                border-radius: 0.3em;
                border: 2px solid $accent;
                box-shadow: 0px 0px 12px -5px rgba(0, 0, 0, 0.3);
                font-weight: 600;

                &:hover {
                    background: $accent;
                }

                &::after {
                    content: none;
                }
            }

            .resumeOffset {
                color: $accent;

                &:hover {
                    color: $primary;
                    background: accent;
                }
            }
        }
    }

    .menu {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: $bg;
        transition: 0.3s all;
        transform: translateX(-100%);
    }

    .menuState {
        transform: translateX(0);
    }
}

@media only screen and (max-width: 1130px) {
    .Navbar {
        .bar {
            .left {
                .menuIcon {
                    width: 1.8em;
                    margin-right: 1.5em;
                }
            }

            .navLinks {
                width: max-content;
                .link {
                    display: none;
                }

                .resume {
                    display: block;
                }
            }
        }

        .menu {
            display: flex;
            justify-content: center;
            align-items: center;

            .menuLinks {
                width: 100%;
                text-align: center;
                list-style: none;
                font-size: 1.5em;
                margin-bottom: 4em;

                li {
                    margin: 1em 0;

                    a {
                        color: $secondary;
                        border-bottom: 0.1em solid $secondary3;
                        text-decoration: none;

                        &:hover {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
